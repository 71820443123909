<template>
	<div style="display: flex;">

		<div class="dis" style="flex-wrap: wrap;justify-content: flex-start;">
			<!-- 已上传的视频列表start -->
			<div v-for="(item,index) in videoList" @mouseenter="enter(index)" @mouseleave="leave" :key="index" style="position: relative;margin-right: 10px;overflow: hidden;border-radius: 5px;">
				<video :src="item.url" :style="{'width': videoShowWidth+'px','border-radius':'5px'}" />

				<div v-if="mouseenterIndex==index" style="cursor: pointer; font-size: 17px; position: absolute;right: 0px;top:-3px;background: rgba(0,0,0,0.5);width: 100%;height: 100%;border-radius: 5px;display: flex;justify-content: center;align-items: center;color: #fff;">
					<i @click="preImg(item.url)" class="el-icon-zoom-in" style="margin-right: 10px;"></i>
					<i @click="del(index)" class="el-icon-delete"></i>
				</div>


			</div>
			<!-- 已上传的视频列表end -->

			<el-upload class="my-el-upload" :action="uploadUrl" :accept="accept" :limit="limit" :data="uploadAxajParam" :show-file-list="false" :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo" :on-exceed="limitExceed" :on-error="videoError" :file-list="videoList">

				<img v-if="videoList.length < limit" src="../../assets/videoUpload.png" style="width: 60px;height: 60px;" />

			</el-upload>
		</div>

		<!-- 全屏透明的loading加载start -->
		<div v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->

		<!--浏览视频的弹窗start-->
		<el-dialog @close="closePrePopBefore" :modal="false" width="400px" footer-hide title="预览" :visible.sync="isShowPreVideoPop">
			<video ref="preVideo" controls :src="preVideoUrl" style="width: 100%"/>
		</el-dialog>
		<!--浏览视频的弹窗end-->

	</div>
</template>

<script>
	import config from '../../util/config.js';
	export default {
		props: {
			//可上传的文件类型 video/mp4,video/xxxx 或 video/*
			accept: {
				type: String,
				default: 'video/mp4'
			},

			//上传的文件列表
			videoList: {
				type: Array,
				default: function() {
					return []
				}
			},
			//可上传文件的总个数
			limit: {
				type: Number,
				default: 6
			},

			//上传文件到服务器文件夹名路径
			dir: {
				type: String,
				default: 'video/'
			},
			// 最大允许上传的文件大小，单位MB
			fileSize: {
				type: Number,
				default: 200,
			},
			//视频显示的宽度
			videoShowWidth: {
				type: Number,
				default: 60,
			},
			


		},
		name: 'uploadVideo',
		components: {},
		data() {
			return {
				config:config,
				mouseenterIndex: -1, //鼠标移入到的图片索引号
				uploadUrl: '', //上传到的文件地址
				uploadAxajParam: {}, //文件上传时携带的请求参数
				loading: false,
				isShowPreVideoPop: false, //是否弹出浏览弹窗
				preVideoUrl: '', //浏览的视频url
			}
		},
		mounted() {

		},
		methods: {
			//关闭浏览视频弹窗前暂停视频播放
			closePrePopBefore(){
				this.$refs.preVideo.pause();
				
			},
			//上传前回调
			async beforeUploadVideo(file) {
				//先调用store中的方法检查上传文件的令牌是否过期
				let uploadParamObj = await this.$store.dispatch('getOSSUploadSign');
				console.log('uploadParamObj=>', uploadParamObj)

				var fileSize = file.size / 1024 / 1024 < this.fileSize; //控制大小 MB 修改50的值即可

				if (this.accept.indexOf(file.type) == -1) { //控制格式
					this.$message.error('请上传正确的视频格式')
					this.loading = false;
					this.$emit('isShowLoading',false)
					return false;
				}
				if (!fileSize) {
					this.$message.error('视频大小不能超过' + this.fileSize + 'MB')
					this.loading = false;
					this.$emit('isShowLoading',false)
					return false;
				}
				const suffix = file.name.substring(file.name.lastIndexOf('.'))
				this.isShowUploadVideo = false;
				console.log('准备开始上传=>')
				this.loading = true;
				this.$emit('isShowLoading',true)
				console.log('loading',this.loading)
				const name = new Date().getTime() + Math.floor(Math.random() * 10000) //生成上传的文件名
				this.uploadUrl = this.config.qiniuUploadUrl; //上传到的服务器地址
				this.uploadAxajParam = { //上传到文件服务器时携带的参数
					"token":uploadParamObj.token,
					"key": "shop/" + name + suffix //上传到的路径地址/文件名.后缀名
				}
				console.log(1111)
			},
			
			//上传成功回调
			handleVideoSuccess(res, file) {

				console.log('上传成功后=>', res, file);
				//一定要在setTimeout里写，否则会出现 Uncaught TypeError: Cannot set properties of null (setting 'status')错误
				setTimeout(() => {
					if (res.key) {
						this.videoList.push({
							url: this.config.qiniuUrl+res.key
						});

						this.$emit('uploadSuccess', this.config.qiniuUrl+res.key); //触发标签上的事件uploadSuccess把上传成功的列表传给父标签
						console.log('上传成功后的videoList', this.videoList)

					} else {
						this.$message.error("上传失败！");
					}
					this.loading = false;
					this.$emit('isShowLoading',false)
				}, 500);


			},


			//清空图片列表
			clearImgList() {
				this.videoList = [];
			},
			//鼠标移入图片
			enter(index) {
				console.log('移入', index)
				this.mouseenterIndex = index;
			},
			//鼠标移除图片
			leave() {
				console.log('移出')
				this.mouseenterIndex = -1;
			},
			//浏览视频
			preImg(url) {
				this.preVideoUrl = url;
				this.isShowPreVideoPop = true;
			},
			//删除文件
			del(index) {
				this.videoList.splice(index, 1);
				console.log('删除后的列表', this.videoList)
			},

			//获取文件列表
			getVideoList() {
				return this.videoList;
			},


			//上传失败
			videoError() {
				this.$message.error('上传失败');
				this.loading = false;
				this.$emit('isShowLoading',false)
			},
			//上传超出个数
			limitExceed() {
				this.$message.error('只能上传' + this.limit + '个');
				this.loading = false;
				this.$emit('isShowLoading',false)
			},



		}
	}
</script>

<style scoped>

</style>
