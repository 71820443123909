<template>
	<div style="display: flex;">



		<div class="dis" style="flex-wrap: wrap;justify-content: flex-start;">
			<!-- 已上传的图片列表start -->
			<div v-for="(item,index) in imgList" @mouseenter="enter(index)" @mouseleave="leave" :key="index" style="position: relative;margin-right: 10px;overflow: hidden;border-radius: 5px;">
				<img :src="item.url" style="width: 60px;height: 60px;border-radius: 5px;" />

				<div v-if="mouseenterIndex==index" style="cursor: pointer; font-size: 17px; position: absolute;right: 0px;top:0;background: rgba(0,0,0,0.5);width: 60px;height: 60px;border-radius: 5px;display: flex;justify-content: center;align-items: center;color: #fff;">
					<i @click="preImg(item.url)" class="el-icon-zoom-in" style="margin-right: 10px;"></i>
					<i @click="del(index)" class="el-icon-delete"></i>
				</div>


			</div>
			<!-- 已上传的图片列表end -->

			<el-upload v-if="isShowUploadBtn" class="my-el-upload" multiple :accept="accept" :limit="limit" :action="uploadUrl" :show-file-list="false"  :data="uploadAxajParam" :before-upload="imageBefore" :on-success="editImageSuccess" :on-exceed="limitExceed" :on-error="imageError" :file-list="imgList">

				<img v-if="imgList.length < limit" src="../../assets/upload.png" style="width: 60px;height: 60px;" />

			</el-upload>
		</div>

		<!-- 全屏透明的loading加载start -->
		<div v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->

		<!--浏览图片的弹窗start-->
		<el-dialog :modal="false" width="400px" footer-hide title="预览" :visible.sync="isShowPreImgPop">
			<img :src="preImgUrl" style="width: 100%">
		</el-dialog>
		<!--浏览图片的弹窗end-->

	</div>
</template>

<script>
	import config from '../../util/config.js';
	
	export default {
		props: {
			//是否显示上传按钮
			isShowUploadBtn: {
				type: Boolean,
				default: true
			},
			//可上传的文件类型 image/png,image/jpeg
			accept: {
				type: String,
				default: 'image/png'
			},
			//可上传的文件后缀名 '.png'
			fileAfterName: {
				type: String,
				default: null
			},

			//上传的文件列表
			imgList: {
				type: Array,
				default: function() {
					return []
				}
			},
			//用于for循环时的index
			eleIndex: {
				type: Number,
				default: -1
			},
			
			//可上传文件的总个数
			limit: {
				type: Number,
				default: 6
			},
			//可上传文件图片的宽度
			imgWidth: {
				type: Number,
				default: 0
			},
			//可上传文件图片的高度
			imgHeight: {
				type: Number,
				default: 0
			},
			//上传文件到服务器文件夹名路径
			dir: {
				type: String,
				default: 'img/'
			},
			// 最大允许上传的文件大小，单位MB
			fileSize: {
				type: Number,
				default: 3,
			},



		},
		name: 'uploadImg',
		components: {},
		data() {
			return {
				config:config,
				mouseenterIndex: -1, //鼠标移入到的图片索引号
				uploadUrl: '', //上传到的文件地址
				uploadAxajParam: {}, //文件上传时携带的请求参数
				loading: false,
				isShowPreImgPop: false, //是否弹出浏览弹窗
				preImgUrl: '', //浏览的图片url
			}
		},
		mounted() {

		},
		methods: {
			//清空图片列表
			clearImgList() {
				this.imgList = [];
			},
			//鼠标移入图片
			enter(index) {
				console.log('移入', index)
				this.mouseenterIndex = index;
			},
			//鼠标移除图片
			leave() {
				console.log('移出')
				this.mouseenterIndex = -1;
			},
			//浏览图片
			preImg(url) {
				this.preImgUrl = url;
				this.isShowPreImgPop = true;
			},
			//删除文件
			del(index) {
				this.imgList.splice(index, 1);
				console.log('删除后的列表', this.imgList)
			},

			//获取文件列表
			getImgList() {
				return this.imgList;
			},

			//每个文件上传之前
			async imageBefore(file) {
				//先调用store中的方法检查上传文件的令牌是否过期
				let uploadParamObj = await this.$store.dispatch('getOSSUploadSign');
				console.log('uploadParamObj=>', uploadParamObj)

				// 判断图片宽高
				if (!(await this.dpiError(file))) {
					return false
				}
				console.log('1=>')
				// 格式
				const prefix = file.name.substring(0, file.name.lastIndexOf('.'))
				const suffix = file.name.substring(file.name.lastIndexOf('.'))
				//设置了文件后缀名校验
				if (this.fileAfterName) {
					if (suffix !== this.fileAfterName) {
						console.log('suffix: ' + suffix, 'type: ' + file.type)
						this.$message.error('文件格式仅限 ' + this.fileAfterName)
						this.loading = false;
						this.$emit('isShowLoading', false)
						return false
					}
				}


				let isSize = file.size / 1024 / 1024; // 文件大小
				if (this.fileSize && isSize > this.fileSize) {
					this.$message.error(`上传图片大小不能超过${this.fileSize}MB`);
					this.loading = false;
					this.$emit('isShowLoading', false)
					return false;
				}


				console.log('准备开始上传=>')
				this.loading = true;
				this.$emit('isShowLoading', true)
				const name = new Date().getTime() + Math.floor(Math.random() * 10000) //生成上传的文件名
				this.uploadUrl = this.config.qiniuUploadUrl; //上传到的服务器地址
				
				this.uploadAxajParam ={
					"token":uploadParamObj.token,
					"key": "shop/" + name + suffix //上传到的路径地址/文件名.后缀名
				};
				
			},
			//上传失败
			imageError() {
				this.$message.error('上传失败');
				this.loading = false;
				this.$emit('isShowLoading', false)
			},
			//上传超出个数
			limitExceed() {
				this.$message.error('只能上传' + this.limit + '个');
				this.loading = false;
				this.$emit('isShowLoading', false)
			},
			//上传成功后
			editImageSuccess(res, file, fileList) {
				console.log('上传成功后=>', res, file, fileList);
				//一定要在setTimeout里写，否则会出现 Uncaught TypeError: Cannot set properties of null (setting 'status')错误
				setTimeout(() => {
					console.log('===',res)
					this.imgList.push({
						url: this.config.qiniuUrl+res.key
					});
					
					
					this.$emit('uploadSuccess', this.config.qiniuUrl+res.key,this.eleIndex); //触发标签上的事件uploadSuccess把上传成功的列表传给父标签
				}, 500);


				console.log('上传成功后的imgList', this.imgList)
				this.loading = false;
				this.$emit('isShowLoading', false)
			},

			//判断上传的图片宽高是否超出
			dpiError(file) {
				console.log('2=>', file)
				return new Promise((resolve, reject) => {
					const img = new Image()
					const url = window.URL || window.webkitURL

					img.src = url.createObjectURL(file)
					img.onload = () => {


						//设置限制图片文件的宽高
						if (this.imgWidth && this.imgHeight) {
							if (img.width != this.imgWidth && img.height != this.imgHeight) {
								this.$message.error('图片宽高仅限 ' + this.imgWidth + ' * ' + this.imgHeight)
								this.loading = false;
								this.$emit('isShowLoading', false)
								return reject(false)
							}
						}

						return resolve(true)


					}


				})
			},
		}
	}
</script>

<style scoped>



</style>
