<template>
	<div style="background-color: #fff;margin: 10px;border-radius: 3px;">
		<div style="position: sticky;top:0;z-index: 999;background-color: #fff;padding-bottom: 1px;border-bottom: 1px solid #ebeef5;padding-top: 10px;">
			<!--带折叠的搜索start-->
			<div>
				<el-form size="mini" style="display: flex;flex-wrap: wrap;" :style="{'height':isShowCollapse?'auto':'50px'}" ref="searchForm" :model="searchForm" inline label-width="100px">

					<!--收起时显示的搜索项start 4个搜索项为一行-->
					<div v-show="!isShowCollapse" style="width: 80%;display: flex;padding-left: 30px;">



						<el-form-item label="商品分类"  style="width: 23%;display: flex;">
							<el-cascader  v-model="searchForm.typeArr" :options="typeOptions" clearable :props="{ expandTrigger: 'hover' }"></el-cascader>
						</el-form-item>



						<el-form-item label="名称" style="width: 23%;display: flex;">
							<el-input v-model="searchForm.name" placeholder="商品名称" clearable size="mini" />
						</el-form-item>
						<el-form-item label="热卖" style="width: 23%;display: flex;">

							<el-select v-model="searchForm.is_hot" clearable placeholder="是否热卖">
								<el-option label="是" :value="1">是</el-option>
								<el-option label="否" :value="0">否</el-option>
							</el-select>

						</el-form-item>
						
						<el-form-item label="新品" style="width: 23%;display: flex;">
						
							<el-select v-model="searchForm.is_new" clearable placeholder="是否新品">
								<el-option label="是" :value="1">是</el-option>
								<el-option label="否" :value="0">否</el-option>
							</el-select>
						
						</el-form-item>
						
					
						
						


					</div>
					<!--收起时显示的搜索项end-->

					<!--展开时显示的搜索项start 4个搜索项为一行-->
					<div v-show="isShowCollapse" style="display: flex;flex-wrap: wrap;">



						<el-form-item label="商品分类" prop="typeArr">
							<el-cascader style="width: 100%;" v-model="searchForm.typeArr" :options="typeOptions" clearable :props="{ expandTrigger: 'hover' }"></el-cascader>
						</el-form-item>



						<el-form-item label="名称" style="width: 23%;display: flex;">
							<el-input v-model="searchForm.name" placeholder="商品名称" clearable size="mini" />
						</el-form-item>

						<el-form-item label="热卖" style="width: 23%;display: flex;">

							<el-select v-model="searchForm.is_hot" clearable placeholder="是否热卖">
								<el-option label="是" :value="1">是</el-option>
								<el-option label="否" :value="0">否</el-option>
							</el-select>

						</el-form-item>

						<el-form-item label="新品" style="width: 24%;display: flex;">

							<el-select v-model="searchForm.is_new" clearable placeholder="是否新品">
								<el-option label="是" :value="1">是</el-option>
								<el-option label="否" :value="0">否</el-option>
							</el-select>

						</el-form-item>
						
						<el-form-item label="轮播" style="width: 24%;display: flex;">
						
							<el-select v-model="searchForm.is_swiper" clearable placeholder="是否轮播">
								<el-option label="是" :value="1">是</el-option>
								<el-option label="否" :value="0">否</el-option>
							</el-select>
						
						</el-form-item>
						
						<el-form-item label="特价" style="width: 24%;display: flex;">
						
							<el-select v-model="searchForm.is_tejia" clearable placeholder="是否特价">
								<el-option label="是" :value="1">是</el-option>
								<el-option label="否" :value="0">否</el-option>
							</el-select>
						
						</el-form-item>
						
						
						
						<el-form-item label="库存预警" style="width: 23%;display: flex;">
						
							<el-select v-model="searchForm.kuCunYuJing" clearable placeholder="是否预警商品">
								<el-option label="是" :value="1">是</el-option>
								<el-option label="否" :value="0">否</el-option>
							</el-select>
						
						</el-form-item>
						
				
						

					</div>
					<!--展开时显示的搜索项end-->

					<div :style="{'width':isShowCollapse?'100%':'13%','justify-content': isShowCollapse?'flex-end':'center' }" style="height: 30px; display: flex;margin: 0;">
						<div style="flex-shrink: 0;margin-right: 20px;display: flex;justify-content: center;align-items: center;cursor:pointer;color: #9A7E9C;font-size: 14px;line-height: 20px; display: flex;margin-left: 20px;" @click="isShowCollapseF()">
							<span v-if="!isShowCollapse">展开</span>
							<span v-else>收起</span>
							<span style="margin-left: 5px;font-size: 20px;line-height: 20px;" :style="{transform:isShowCollapse?'rotate(180deg)':'rotate(0deg)'}">∨</span>
						</div>
						<el-button round style="margin-right: 10px" @click="handleSearch" type="primary" icon="el-icon-search" size="small">搜索</el-button>
						<el-button round @click="handleReset" size="small">重置</el-button>
						<div v-if="isShowCollapse" style="width: 70px;"></div>
					</div>


				</el-form>
			</div>
			<!--带折叠的搜索end-->

			<!-- 只允许单选的排序start -->
			<div style="margin-top: 10px;display: flex;align-items: center;background-color: #fff;margin-bottom: 10px; justify-content: space-between;">

				<div style="margin-right: 20px;margin-left: 35px;">
					<el-button @click="showAddGoodsPop" type="primary" round icon="el-icon-plus" size="mini">添加商品</el-button>
				</div>
				
				<div class="sortLine" style="font-size: 14px;display: inline-flex; margin-left: 40px; color: #6f6c5f;background-color: #e0f7ed;border-radius: 8px;overflow: hidden;margin-right: 68px;">

					<div class="sortItem dis" @click="sort(-1)" :style="{color: sortIndex==-1?'#fff':'#777','font-weight': sortIndex==-1?'bold':'400','background-color': sortIndex==-1?'#119744':''}">
						默认
					</div>
					
				
					<div class="sortItem dis" @click="sort(1,'sales')" :style="{color: sortIndex==1?'#fff':'#777','font-weight': sortIndex==1?'bold':'400','background-color': sortIndex==1?'#119744':''}">
						销量
						<img v-if="sortIndex==1&&sortContent=='ASC'" :src="shang" style="width: 15px;height: 15px;margin-left: 3px;" />
						<img v-if="sortIndex==1&&sortContent=='DESC'" :src="xia" style="width: 15px;height: 15px;margin-left: 3px;" />
					</div>

				

				</div>
				

			</div> 
			<!-- 只允许单选的排序end -->
		</div>


		<!-- 添加商品弹窗start width="50%"-->
		<el-dialog :close-on-click-modal="false" width="950px" custom-class="m-dialog" title="添加商品" center :visible.sync="isShowAddGoodsPop">
			<add-goods v-if="isShowAddGoodsPop" @success="addAndEditGoodsSuccess"></add-goods>
		</el-dialog>
		<!-- 添加商品弹窗end -->


		<!-- 编辑商品弹窗start width="50%"-->
		<el-dialog :close-on-click-modal="false" width="950px" custom-class="m-dialog" title="编辑商品" center :visible.sync="isShowEditGoodsPop">
			<edit-goods v-if="isShowEditGoodsPop" :goods_id="rowGoods_id" @success="addAndEditGoodsSuccess"></edit-goods>
		</el-dialog>
		<!-- 编辑商品弹窗end -->


		<!-- 显示商品详情弹窗start width="50%"-->
		<el-dialog :close-on-click-modal="false" width="950px" custom-class="m-dialog" title="商品详情" center :visible.sync="isShowGoodsDetailPop">
			<show-goods v-if="isShowGoodsDetailPop" :goods_id="rowGoods_id"></show-goods>
		</el-dialog>
		<!-- 显示商品详情弹窗end -->


		<!--表格start :height="tableHeight+'px'"-->
		<el-table :height="tableHeight" :show-header="false" ref="table" :data="tableData" style="width: 100%;background-color: #fff;margin: 5px;">

			<el-table-column style="width: 100%;">
				<template slot-scope="{ row, index }">
					<div class="tableTem" style="display: flex;margin-left: 20px;">
						<div class="avatar">

							<!--商品图片start-->
							<div style="text-align: center;" class="dis">
								<img :src="row.cover_img" style="width:70px;height:70px;cursor: pointer;" @click="handlePreView(row.cover_img)">
								<!-- <div class="titleFont">主图</div> -->
							</div>
							<!--商品图片end-->

							<div style="width: 180px;">

								<!--头像右侧一个列表项start-->
								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">id：</span>
									<el-tooltip :content="row.id+''" placement="right">
										<div class="dian dianL">{{row.id}}</div>
									</el-tooltip>
								</div>
								<!--头像右侧一个列表项end-->
								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">名称：</span>
									<el-tooltip :content="row.name" placement="right">
										<div class="dian dianL">{{row.name}}</div>
									</el-tooltip>
								</div>



								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">价格：</span>
									<el-tooltip :content="row.price+''" placement="right">
										<div class="dian dianL">{{row.price}}</div>
									</el-tooltip>
								</div>
								
								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">销量：</span>
									<el-tooltip :content="row.sales+''" placement="right">
										<div class="dian dianL">{{row.sales}}</div>
									</el-tooltip>
								</div>
								
								


							</div>
						</div>

						<!-- height: 50px; -->
						<ul  :style="{'width':isMobile?mobileWidth+'px':'','overflow-x':isMobile?'auto':'','overflow-y':isMobile?'hidden':'','flex-wrap':isMobile?'nowrap':''}">


							<li>
								<div class="content">
									<el-tooltip :content="row.is_hot==1?'是':'否'" placement="right">
										<span>
										{{row.is_hot==1?'是':'否'}}
										</span>
									</el-tooltip>
								</div>
								<div class="titleFont dian">是否热卖</div>
							</li>


							<li>
								<div class="content">
									<el-tooltip :content="row.is_new==1?'是':'否'" placement="right">
										<span>{{row.is_new==1?'是':'否'}}</span>
										
									</el-tooltip>
								</div>
								<div class="titleFont dian">是否新品</div>
							</li>
							
							<li>
								<div class="content">
									<el-tooltip :content="row.is_swiper==1?'是':'否'" placement="right">
										<span>{{row.is_swiper==1?'是':'否'}}</span>
										
									</el-tooltip>
								</div>
								<div class="titleFont dian">是否轮播</div>
							</li>
							
							<li>
								<div class="content">
									<el-tooltip :content="row.is_tejia==1?'是':'否'" placement="right">
										<span>{{row.is_tejia==1?'是':'否'}}</span>
										
									</el-tooltip>
								</div>
								<div class="titleFont dian">是否特价</div>
							</li>
							
							
							



							<!-- 一个方块列表项start-->
							<!-- <li>
								<div class="content">
									<el-tooltip :content="row.stock+''" placement="right">
										<div class="dian">{{row.stock}}</div>
									</el-tooltip>
								</div>
								<div class="titleFont">库存</div>
							</li> -->
							<!-- 一个方块列表项end-->

							



							<li>
								<div class="content">
									<el-tooltip :content="row.sku_type==1?'单规格':'多规格'" placement="right">
										<div class="dian">{{row.sku_type==1?'单规格':'多规格'}}</div>
									</el-tooltip>
								</div>
								<div class="titleFont dian">规格类型</div>
							</li>
							
							<li>
								<div class="content">
									<el-tooltip :content="row.categoryTxt" placement="right">
										<div class="dian">{{row.categoryTxt}}</div>
									</el-tooltip>
								</div>
								<div class="titleFont dian">分类</div>
							</li>
							
							<li>
								<div class="content">
									<el-tooltip :content="row.jia_sale?row.jia_sale+'':'无'" placement="right">
										<div class="dian">{{row.jia_sale?row.jia_sale:'无'}}</div>
									</el-tooltip>
								</div>
								<div class="titleFont dian">假销量数</div>
							</li>
							
							
							

							<li>
								<el-tooltip :content="row.date+''" placement="right">

									<div class="content dis">
										{{row.date | timeBreak2}}


									</div>
								</el-tooltip>
								<div class="titleFont dian">操作时间</div>
							</li>



						</ul>


						<div style="width: 100px;display: flex;justify-content: start;align-items: center;">
							<el-dropdown trigger="hover" style="width: 80px;">
								<el-button size="mini" round type="primary">
									操作<i class="el-icon-arrow-down el-icon--right"></i>
								</el-button>
								<el-dropdown-menu slot="dropdown">

									<!-- <el-dropdown-item>
										<el-button type="primary" size="mini" round @click="showGoodsDetail(row)">查看
										</el-button>
									</el-dropdown-item> -->
									<el-dropdown-item>
										<el-button type="success" size="mini" round @click="showEditGoods(row)">编辑
										</el-button>
									</el-dropdown-item>
									<el-dropdown-item>
										<el-button type="danger" size="mini" round @click="delGoods(row)">删除
										</el-button>
									</el-dropdown-item>


								</el-dropdown-menu>
							</el-dropdown>

						</div>



					</div>

				</template>
			</el-table-column>
		</el-table>
		<!--表格end-->

		<!--分页start-->
		<el-row type="flex" justify="end" align="middle" class="page" style="margin-top: 10px;margin-right: 50px;padding-bottom: 10px;">
			<el-pagination background layout="prev, pager, next" :current-page="pageNumber" :page-size="pageSize" :total="total" @current-change="changePage"></el-pagination>
			<span style="font-size: 13px;color: #999;">共 {{total}} 条</span>
		</el-row>
		<!--表格end-->

		<!--浏览图片的弹窗start-->
		<el-dialog width="400px" footer-hide title="预览" :visible.sync="isShowPreImg">
			<img :src="preImgUrl" style="width: 100%">
		</el-dialog>
		<!--浏览图片的弹窗end-->


		<!-- 全屏透明的loading加载start -->
		<div id="pageLoading" v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->
	</div>
</template>

<script>
	import {
		ajax
	} from '../../util/ajax.js';
	import addGoods from '../../components/goods/addGoods.vue';
	import showGoods from '../../components/goods/showGoods.vue';
	import editGoods from '../../components/goods/editGoods.vue';
	export default {
		name: '',
		components: {
			addGoods,
			showGoods,
			editGoods
		},
		data() {
			return {
				isMobile: window.isMobile,
				mobileWidth: window.mobileWidth + 100,
				
				rowGoods_id: '', //列表项的商品id

				isShowCollapse: false, //是否展开搜索项
				searchForm: {
					kuCunYuJing:""

				},
				brand_idArr: [], //品牌列表
				isShowPreImg: false, //是否显示浏览图片的弹窗
				preImgUrl: '', //浏览图片弹窗的图片地址

				isShowAddGoodsPop: false, //是否显示添加商品弹窗
				isShowGoodsDetailPop: false, //是否显示商品详情弹窗
				isShowEditGoodsPop: false, //是否显示编辑商品详情弹窗


				tableHeight: 0, //表格高度
				loading: false,
				tableData: [], //表格数据
				pageNumber: 1, // 当前页数
				pageSize: 10, // 页面大小
				total: 0,

				shang: require("@/assets/shang.png"),
				xia: require("@/assets/xia.png"),
				sortIndex: -1, //排序项
				sortContent: '', //排序项的值 desc 或esc
				sortFlag: true, //排序箭头切换


				typeOptions: [], //分类列表


			}
		},

		filters: {
			//把 2021-07-30 11:07:36 格式为 07-30
			//								11:07
			//使用格式 <p>{{row.stateChangeTime | timeBreak(0)}}</p>    <p>{{row.stateChangeTime | timeBreak(1)}}</p> 
			timeBreak(time, type, from) {
				if (time) {
					return type == 0 ? (from == undefined ? time.substr(5, 5) : time.substr(0, 10)) : (type == 2 ? time
					.substr(0, 10) : (from == undefined ? time.substr(11, 5) : time.substr(11, 8)));
				} else {
					return time;
				}
			},

			timeBreak2(val) {

				let v = val.split(' ')[0];

				return v;
			},

		},
		created() {
			this.getTableHeight();
		},
		mounted() {

			//挂载window.onresize事件(动态设置table高度)
			let _this = this;
			window.onresize = () => {
				if (_this.resizeFlag) {
					clearTimeout(_this.resizeFlag);
				}
				_this.resizeFlag = setTimeout(() => {
					_this.getTableHeight();
					_this.resizeFlag = null;
				}, 100);
			};
			
			if (this.$route.query.kuCunYuJing) { //库存预警的标识
				this.searchForm.kuCunYuJing = 1;
			}
			
			this.getTypeArrArr();
			this.initData();
		},

		methods: {

			//初始化表格数据
			async initData() {
				this.loading = true
				var searchForm = this.searchForm;
				
				if (searchForm.brand_id === "") { //select未选中为空字符串
					searchForm.brand_id = null; //为null后就不会作为http请求参数了带给后端了
				}
				
				if (searchForm.name === "") { //select未选中为空字符串
					searchForm.name = null; //为null后就不会作为http请求参数了带给后端了
				}
				
			

				if (searchForm.is_hot === "") {
					searchForm.is_hot = null; //搜索的 是否推荐
				}

				if (searchForm.is_new === "") {
					searchForm.is_new = null; //搜索的 是否优惠
				}

				if (searchForm.is_swiper === "") {
					searchForm.is_swiper = null; //搜索的 是否轮播
				}
				
				if (searchForm.is_tejia === "") {
					searchForm.is_tejia = undefined; //搜索的 是否轮播
				}
				
				
				
				if (searchForm.kuCunYuJing === "") {
					searchForm.kuCunYuJing = null; //搜索的 库存预警
				}
				
				if (searchForm.sort === "") {
					searchForm.sort = null; //排序的
				}
				if (searchForm.sort_rule === "") {
					searchForm.sort_rule = null; //排序的
				}

				let param = {
					category1_id:searchForm.typeArr ? searchForm.typeArr[0] : null,
					category2_id:searchForm.typeArr ? searchForm.typeArr[1] : null,
					is_hot:searchForm.is_hot,
					name: searchForm.name, //搜索的 商品名称
					is_new:searchForm.is_new,
					is_swiper:searchForm.is_swiper,
					is_tejia:searchForm.is_tejia,
					kuCunYuJing:searchForm.kuCunYuJing,
					sort:searchForm.sort,
					sort_rule:searchForm.sort_rule,
					count: this.pageSize,
					page: this.pageNumber
				}
			
				let ress = await ajax('/getGoodsList', 'POST', param);
				this.loading = false;
			

				this.total = ress.data.data.total;
				this.tableData = ress.data.data.list;
			},



			

			//获取一级和二级分类列表
			async getTypeArrArr() {
				this.loading = true
				let res = await ajax('/getCategoryAll', 'GET'); //获取一级分类列表

				let arr = res.data.data.list;
			


				let typeArr = [];
				for (let i of arr) {
					let obj = {
						value: i.id + '',
						label: i.name,
						children: []
					};


					for (let ii of i.category2List) {
						obj.children.push({
							value: ii.id,
							label: ii.name
						});
					}
					typeArr.push(obj);
				}

				this.loading = false;
				this.typeOptions = typeArr;
			},


			//点击查看商品按钮
			showGoodsDetail(item) {
				this.rowGoods_id = item.id;
				this.isShowGoodsDetailPop = true;
			},
			//点击修改商品按钮
			showEditGoods(item) {
				console.log('ssssssssss=>>>', item.id)
				this.rowGoods_id = item.id;
				this.isShowEditGoodsPop = true;

			},
			//删除商品
			async delGoods(row) {

				this.$confirm('确定要删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.loading = true
					let ress = await ajax('/delGoods', 'GET', {
						id: row.id
					});
					this.loading = false;
					this.$message({
						message: '成功',
						type: 'success'
					});

					this.initData();
				});

			},

			//点击列表的优惠按钮
			async youhuiSwitchChange(item) {
				// this.loading = true
				// let ress = await ajax('hq/goods/list', 'GET');
				// this.loading = false;
			},

			//显示或隐藏添加商品弹窗
			showAddGoodsPop() {
				this.isShowAddGoodsPop = true;
			},

			//计算table高度(动态设置table高度)
			getTableHeight() {
				let tableH = 250; //距离页面下方的高度,值越大表格高度越小
				let tableHeightDetil = window.innerHeight - tableH;
				if (tableHeightDetil <= 300) {
					this.tableHeight = 300;
				} else {
					this.tableHeight = window.innerHeight - tableH;
				}
			},



			//展开搜索项
			isShowCollapseF() {
				this.isShowCollapse = !this.isShowCollapse;
			},
			//翻页
			changePage(pageNumber) {
				this.tableData = []; //这种可以让滚动条回到顶部
				this.pageNumber = pageNumber
				this.initData()
			},

			//点击头像
			handlePreView(url) {
				this.preImgUrl = url
				this.isShowPreImg = true
			},


			//排序 index是点击的项的索引,formName是属性名
			sort(index, formName) {
				//点击了默认排序
				if (index == -1) {
					this.sortIndex = -1;
					this.sortContent = ''; //排序的值 desc / esc
					this.sortFlag = true;
					//使用index把所有的搜索项值为''然后再发送请求获取到默认列表
					this.pageNumber = 1; //页码修改为1
					this.tableData = []; //表格数据修改为空
					this.searchForm.sort = ''; //排序的属性名
					this.searchForm.sort_rule = ''; //排序的方式
					//获取默认排序的表格数据

					this.initData(); //获取默认排序的表格数据
					return
				}
				//点击还是当前搜索项那么就进行升降序切换
				if (index == this.sortIndex) {
					this.sortFlag = !this.sortFlag;
					//把当前点击的搜索项切换为desc为降序
					if (this.sortFlag) {
						this.sortContent = 'DESC'; //排序的值 desc / esc
						//使用index把所有的搜索项值为''然后把当前点击的搜索项的值修改为desc
						this.pageNumber = 1; //页码修改为1
						this.tableData = []; //表格数据修改为空
						this.searchForm.sort = formName; //排序的属性名
						this.searchForm.sort_rule = 'DESC'; //排序的方式
						//获取表格列表

						this.initData(); //获取当前点击的搜索项的表格数据
					} else {
						//把当前点击的搜索项切换为esc为升序
						//使用index把所有的搜索项值为''然后把当前搜索项的值修改为asc
						this.sortContent = 'ASC'; //排序的值 desc / asc
						this.pageNumber = 1; //页码修改为1
						this.tableData = []; //表格数据修改为空
						this.searchForm.sort = formName; //排序的属性名
						this.searchForm.sort_rule = 'ASC'; //排序的方式
						//获取表格列表
						this.initData(); //获取当前点击的搜索项的表格数据
					}
					return
				}
				//当前点击的搜索项是其他的搜索项
				this.sortIndex = index; //当前点击的搜索项的索引号
				this.sortContent = 'DESC'; //排序的值 desc / esc
				this.sortFlag = true;
				//使用index把所有的搜索项值为''然后把当前搜索项的值修改为desc然后再发送请求获取到列表
				this.pageNumber = 1; //页码修改为1
				this.tableData = []; //表格数据修改为空
				this.searchForm.sort = formName; //排序的属性名
				this.searchForm.sort_rule = 'DESC'; //排序的方式
				//获取表格列表

				this.initData(); //获取当前点击的搜索项的排序的表格数据
			},


			//添加或编辑商品成功
			addAndEditGoodsSuccess() {
				console.log("接收到成功的通知")
				this.isShowAddGoodsPop = false;
				this.isShowEditGoodsPop = false;
				this.initData();
			},

			//搜索
			handleSearch() {
				this.pageNumber = 1;
				this.initData()
			},

			//重置
			handleReset() {
				this.$refs.searchForm.resetFields();
				this.searchForm = {};
				this.sortIndex = -1;
				this.sortContent = ''; //排序的值 desc / esc
				this.sortFlag = true;
				this.pageNumber = 1
				this.pageSize = 10
				this.tableData = []; //表格数据修改为空
				this.initData()
			},






		}


	}
</script>

<style scoped>
	.tableTem ul {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;

	}

	.tableTem ul li {
		list-style: none;

		width: 110px;
		min-height: 60px;
		border-left: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		margin-bottom: -1px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 3px;


	}

	.tableTem .content {

		padding-top: 3px;
		font-size: 14px;
		color: #2d8cf0;
		font-weight: 600;
	}

	.tableTem .avatar img {
		border-radius: 10px;
		width: 60px;
		height: 60px;
	}

	.tableTem .avatar {

		padding-right: 20px;
		display: flex;
		justify-content: center;

	}

	.tableTem .dian {
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100px;
	}

	.tableTem .dianL {
		text-align: left;
		font-size: 13px;
		color: #333;
	}


	.titleFont {
		font-size: 12px !important;
		flex-shrink: 0;
		line-height: 25px;
		color: rgb(136, 136, 136);
	}





	.sortLine {
		display: flex;
		align-items: center;
	}

	.sortItem {

		height: 100%;
		color: rgb(156, 156, 156);
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		min-width: 80px;
	}

	.sortItem:hover {
		background-color: #119744 !important;
		color: #fff !important;
	}

	.sortLine .sortItem:last-child {
		border-right: none;
	}

	>>>.el-table tbody tr:hover>td {
		background-color: #ffffff !important
	}


	>>>.el-icon-arrow-down {
		font-size: 12px;
	}

	>>>.demonstration {
		display: block;
		color: #8492a6;
		font-size: 14px;
		margin-bottom: 20px;
	}

	>>>.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	>>>.el-table td {
		padding: 10px 0 !important;
	}
</style>
