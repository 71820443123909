<template>
	<div>

		<el-tabs v-model="tabsActive">
			<el-tab-pane disabled label="基础信息" name="1">
				<div style="padding-top: 20px;padding-left: 66px;width: 460px;">

					<el-form label-width="100px" size="small" ref="form1" :rules="form1Rules" :model="form1">



						<el-form-item label="商品分类" prop="typeArr">

							<el-cascader style="width: 100%;" v-model="form1.typeArr" :options="typeOptions" clearable :props="{ expandTrigger: 'hover' }"></el-cascader>
						</el-form-item>

						<el-form-item label="商品名称" prop="name">
							<el-input v-model="form1.name"></el-input>
						</el-form-item>
						
						<el-form-item label="商品假销量" prop="jia_sale">
							<el-input v-model="form1.jia_sale"></el-input>
						</el-form-item>

						<!--   -->
						<el-form-item label="封面图" prop="image" style="position: relative;">
							<div style="position: absolute;top: 20px;left: -59px;font-size: 12px;color: #aaa;">320*300</div>
							<upload-img @isShowLoading="isShowLoadingFn" :limit='1' accept="image/*" ref="fengMianUpload" :imgList="form1.image"></upload-img>

						</el-form-item>

						<!--  -->
						<el-form-item label="商品轮播图" prop="images" style="position: relative;">
							<div style="position: absolute;top: 20px;left: -59px;font-size: 12px;color: #aaa;">750*735</div>
							<upload-img @isShowLoading="isShowLoadingFn" :limit='6' accept="image/*" ref="goodsImgUpload" :imgList="form1.images"></upload-img>

						</el-form-item>

						<el-form-item label="是否特价" prop="is_tejia">
							<el-switch v-model="form1.is_tejia"></el-switch>
						</el-form-item>

						<el-form-item label="是否新品" prop="is_recommend">
							<el-switch v-model="form1.is_recommend"></el-switch>
						</el-form-item>




						<el-form-item label="是否热卖" prop="is_discount">
							<el-switch v-model="form1.is_discount"></el-switch>
						</el-form-item>

						<el-form-item label="是否轮播" prop="is_swiper">
							<el-switch v-model="form1.is_swiper"></el-switch>
						</el-form-item>

						<el-form-item v-if="form1.is_swiper==1" label="轮播封面图" prop="index_swiper_img" style="position: relative;">
							<span slot="label">
								<span style="color: red;">*</span>
								轮播封面图
							</span>

							<div style="position: absolute;top: 20px;left: -59px;font-size: 12px;color: #aaa;">370*700</div>
							<upload-img @isShowLoading="isShowLoadingFn" :limit='1' accept="image/*" ref="recommendImgUpload" :imgList="form1.index_swiper_img"></upload-img>
						</el-form-item>

					</el-form>

				</div>
				<div class="dis" style="justify-content: flex-end;">

					<el-button round style="margin-left: 10px" @click="goNext('form1')" type="primary" size="small">下一步</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane disabled label="规格库存" name="2">
				<div>

					<div style="padding-top: 20px;width: 100%;">
						<div style="display: flex;align-items: center; margin-left: 100px;">
							<el-radio-group size="small" v-model="specs_type" style="margin-bottom: 30px;">
								<el-radio-button label="1">单规格</el-radio-button>
								<el-radio-button label="2">多规格</el-radio-button>
							</el-radio-group>
						</div>


						<!-- 单规格表单start -->
						<el-form style="width: 300px; margin-left: 50px;" v-if="specs_type=='1'" label-width="100px" size="small" ref="form2By1" :rules="form2By1Rules" :model="form2By1">

							<el-form-item label="价格" prop="price">
								<el-input v-model="form2By1.price" type="number"></el-input>
							</el-form-item>
							<el-form-item label="库存" prop="stock">
								<el-input v-model="form2By1.stock" type="number"></el-input>
							</el-form-item>


						</el-form>
						<!-- 单规格表单end -->

						<!-- 多规格表单start -->
						<el-form style="" v-if="specs_type=='2'" label-width="100px" size="small" ref="form2By2" :rules="form2By2Rules" :model="form2By2">


							<div style="margin-bottom: 20px;display: flex;align-items: center;margin-top: 20px;">
								<div style="width: 5px;height: 13px;background-color:#119744;border-radius: 3px;margin-right: 5px;"></div>

								<div>规格名:</div>


							</div>
							<div style="display: flex;align-items: center;">
								<el-form-item label="" prop="name" style="margin-bottom: 40px;">
									<el-input style="width: 150px;" v-model="addSkuName" placeholder="请输入规格名称"></el-input>
									<el-button type="primary" @click="addSkuNameFn" style="margin-left: -5px;position: relative;border-radius: 0;border-top-right-radius: 4px;border-bottom-right-radius: 4px;">添加</el-button>
								</el-form-item>
							</div>






							<div style="margin-bottom: -20px;display: flex;align-items: center;">
								<div style="width: 5px;height: 13px;background-color:#119744;border-radius: 3px;margin-right: 5px;"></div>规格列表:
							</div>



							<div v-for="(item1,index1) in list" :key="index1">
								<el-divider></el-divider>
								<el-form-item :label="item1.name" prop="name">

									<div>

										<el-input style="width: 150px;" v-model="item1.skuValInput" placeholder="请输入规格值"></el-input>
										<el-button type="primary" @click="addSkuValueFn(item1.id,index1)" style="border-radius: 0;margin-left: -5px;position: relative;border-top-right-radius: 4px;border-bottom-right-radius: 4px;margin-bottom: 10px;">添加</el-button>

									</div>
									
									<el-collapse>
									  <el-collapse-item title="">
										  <template slot="title">
										        <div style="color: #a5a5a5;font-size: 12px;margin-left: 10px;">查看规格值</div>
										    </template>
										<el-checkbox-group @change="handleClick" v-model="checkList[index1].list" size="small">
											<el-checkbox v-for="(item2,index2) in item1.list" :key="index2" :label="item2.id" border style="margin: 0; margin-top: 10px;margin-right: 10px; min-width: 80px;">{{item2.sku_value}}</el-checkbox>
										</el-checkbox-group>
									  </el-collapse-item>
									</el-collapse>

									


								</el-form-item>
							</div>

							<el-divider></el-divider>



							<!-- 多规格批量修改表格start -->
							<table v-if="skuTable.length>0" class="gridtable" style="margin-bottom: 20px;">

								<div style="margin-top: 50px;margin-bottom: 10px;display: flex;align-items: center;">
									<div style="width: 5px;height: 13px;background-color:#119744;border-radius: 3px;margin-right: 5px;"></div> 批量设置:
								</div>
								<tr>

									<th>图片</th>
									<th>价格</th>
									<th>库存</th>
									<th>操作</th>
								</tr>
								<tr>

									<td class="dis" style="border-right: 0;border-top: 0;">
										<upload-img @isShowLoading="isShowLoadingFn" :limit='1' accept="image/*" :imgList="plSkuObj.img" ref="duoSkuPiLiangImgUpload"></upload-img>
									</td>
									<td>
										<el-input v-model="plSkuObj.price" type="number" />
									</td>
									<td>
										<el-input v-model="plSkuObj.stock" type="number" />
									</td>

									<td>
										<el-button round size="mini" type="primary" @click="plAddSkuTableData">确定</el-button>
									</td>
								</tr>

							</table>

							<!-- 多规格批量修改表格end -->

							<!-- 多规格表格start -->
							<table v-if="skuTable.length>0" class="gridtable" style="margin-bottom: 100px;">

								<div style="margin-top: 50px;margin-bottom: 10px;display: flex;align-items: center;">
									<div style="width: 5px;height: 13px;background-color:#119744;border-radius: 3px;margin-right: 5px;"></div> 商品属性:
									<div style="padding-left: 10px;color: red;">( 商品规格顺序请按照价格从低到高添加 )</div>
								</div>
								<tr>

									<th>规格属性</th>
									<th>图片</th>
									<th>价格</th>
									<th>库存</th>
									<th>操作</th>
								</tr>
								<tr v-for="(item,index) in skuTable" :key="index">

									<td style="min-width: 250px;">{{item.name}}</td>
									<td class="dis" style="border-right: 0;border-top: 0;border-left: 0;">
										<upload-img @isShowLoading="isShowLoadingFn" :limit='1' :eleIndex="index" accept="image/*" :imgList="item.img" @uploadSuccess="duoSkuItemImgUploadSuccess"></upload-img>
									</td>
									<td>
										<el-input v-model="item.price" type="number" />
									</td>
									<td>
										<el-input v-model="item.stock" type="number" />
									</td>



									<td style="min-width: 100px;">
										<div @click="delSku(index)" class="dis" style="cursor: pointer;">删除</div>
									</td>

								</tr>

							</table>

							<!-- 多规格表格end -->

						</el-form>

						<!-- 多规格表单end -->

					</div>


					<div class="dis" style="justify-content: flex-end;">
						<el-button round @click="goUp" size="small">上一步</el-button>
						<el-button round style="margin-left: 10px" @click="goNext(specs_type=='1'?'form2By1':'form2By2')" type="primary" size="small">下一步</el-button>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane disabled label="商品详情" name="3">


				<div id="goodsDesc">


					<!-- 使用el-upload 标签劫持原本图片上传事件，实现视频上传 -->
					<upload-img @isShowLoading="isShowLoadingFn" v-show="false" accept="image/*" :limit="50" ref="descUploadImg" @uploadSuccess="quillUploadImgSuccess"></upload-img>

					<!-- 使用el-upload 标签劫持原本视频上传事件，实现视频上传 -->
					<div id="goodsDescVide">
						<upload-video @isShowLoading="isShowLoadingFn" v-show="false" ref="descUploadVideo" @uploadSuccess="quillUploadVideoSuccess"></upload-video>
					</div>


					<!-- 富文本start -->
					<div style="display: flex;justify-content: space-around;align-items: center;">
						<el-form :model="form3" size="small" ref="form3" :rules="form3Rules">
							<el-form-item prop="description" style="margin-bottom: 40px;width: 375px;height: 640px;">
								<quill-editor v-model="form3.description" v-maxWindow ref="myQuillEditor" :options="editorOption"></quill-editor>
							</el-form-item>
						</el-form>
						<!-- <div id="my-phone" style="position: relative;margin-left: 20px;box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);width: 375px;height: 640px;top:-15px;">
							<img style="position: absolute;top:0;left: 0;width: 375px;height: 64px;z-index: 2;" src="../../assets/phone.jpg" />

							<div v-html="form3.description" style="position: absolute;top:0;left: 0;width: 379px;height: 640px;overflow-y: scroll;padding-top: 64px;"></div>
						</div> -->
					</div>
					<!-- 富文本end -->

					<div class="dis" style="justify-content: flex-end;">
						<el-button round @click="goUp" size="small">上一步</el-button>
						<el-button round style="margin-left: 10px" @click="goNext('form3')" type="primary" size="small">完成</el-button>
					</div>
				</div>
			</el-tab-pane>


		</el-tabs>

		<!-- 全屏透明的loading加载start -->
		<div v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->






	</div>
</template>

<script>
	import uploadImg from '../upload/uploadImg.vue'
	import uploadVideo from '../upload/uploadVideo.vue'
	import quillVideo from '../quill/video.js'


	import {
		ajax
	} from '../../util/ajax.js';

	import {
		quillEditor
	} from 'vue-quill-editor' // 调用富文本编辑器
	import 'quill/dist/quill.core.css' // 富文本编辑器主题样式(在下面配置项editorOption对象中的属性theme中用到了这个主题) 
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import * as Quill from 'quill'; // 富文本基于quill

	Quill.register(quillVideo, true);
	//import "./quillEditor.css";//引入字体
	var fonts = [
		"SimSun",
		"SimHei",
		"Microsoft-YaHei",
		"KaiTi",
		"FangSong",
		"Arial",
		"Times-New-Roman",
		"sans-serif",
	];
	// 自定义字号的大小，注意下面的内容要和css里面对应上
	var sizes = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px"];

	var Size = Quill.import("formats/size");
	Size.whitelist = sizes;
	var Font = Quill.import("formats/font");
	Font.whitelist = fonts; //将字体加入到白名单
	Quill.register(Font, true);
	//vue-quill-editor富文本编辑器字体和字体大小end

	// vue-quill-editor富文本编辑器鼠标移入工具栏按钮的标题文字start
	const titleConfig = [{
			Choice: '.ql-bold',
			title: '加粗'
		},
		{
			Choice: '.ql-italic',
			title: '斜体'
		},
		{
			Choice: '.ql-underline',
			title: '下划线'
		},
		{
			Choice: '.ql-header',
			title: '段落格式'
		},
		{
			Choice: '.ql-strike',
			title: '删除线'
		},
		{
			Choice: '.ql-blockquote',
			title: '块引用'
		},
		{
			Choice: '.ql-code',
			title: '插入代码'
		},
		{
			Choice: '.ql-code-block',
			title: '插入代码段'
		},
		{
			Choice: '.ql-font',
			title: '字体'
		},
		{
			Choice: '.ql-size',
			title: '字体大小'
		},
		{
			Choice: '.ql-list[value="ordered"]',
			title: '编号列表'
		},
		{
			Choice: '.ql-list[value="bullet"]',
			title: '项目列表'
		},
		{
			Choice: '.ql-direction',
			title: '文本方向'
		},
		{
			Choice: '.ql-header[value="1"]',
			title: 'h1'
		},
		{
			Choice: '.ql-header[value="2"]',
			title: 'h2'
		},
		{
			Choice: '.ql-align',
			title: '对齐方式'
		},
		{
			Choice: '.ql-color',
			title: '字体颜色'
		},
		{
			Choice: '.ql-background',
			title: '背景颜色'
		},
		{
			Choice: '.ql-image',
			title: '图像'
		},
		{
			Choice: '.ql-video',
			title: '视频'
		},
		{
			Choice: '.ql-link',
			title: '添加链接'
		},
		{
			Choice: '.ql-formula',
			title: '插入公式'
		},
		{
			Choice: '.ql-clean',
			title: '清除字体格式'
		},
		{
			Choice: '.ql-script[value="sub"]',
			title: '下标'
		},
		{
			Choice: '.ql-script[value="super"]',
			title: '上标'
		},
		{
			Choice: '.ql-indent[value="-1"]',
			title: '向左缩进'
		},
		{
			Choice: '.ql-indent[value="+1"]',
			title: '向右缩进'
		},
		{
			Choice: '.ql-header .ql-picker-label',
			title: '标题大小'
		},
		{
			Choice: '.ql-header .ql-picker-item[data-value="1"]',
			title: '标题一'
		},
		{
			Choice: '.ql-header .ql-picker-item[data-value="2"]',
			title: '标题二'
		},
		{
			Choice: '.ql-header .ql-picker-item[data-value="3"]',
			title: '标题三'
		},
		{
			Choice: '.ql-header .ql-picker-item[data-value="4"]',
			title: '标题四'
		},
		{
			Choice: '.ql-header .ql-picker-item[data-value="5"]',
			title: '标题五'
		},
		{
			Choice: '.ql-header .ql-picker-item[data-value="6"]',
			title: '标题六'
		},
		{
			Choice: '.ql-header .ql-picker-item:last-child',
			title: '标准'
		},
		{
			Choice: '.ql-size .ql-picker-item[data-value="small"]',
			title: '小号'
		},
		{
			Choice: '.ql-size .ql-picker-item[data-value="large"]',
			title: '大号'
		},
		{
			Choice: '.ql-size .ql-picker-item[data-value="huge"]',
			title: '超大号'
		},
		{
			Choice: '.ql-size .ql-picker-item:nth-child(2)',
			title: '标准'
		},
		{
			Choice: '.ql-align .ql-picker-item:first-child',
			title: '居左对齐'
		},
		{
			Choice: '.ql-align .ql-picker-item[data-value="center"]',
			title: '居中对齐'
		},
		{
			Choice: '.ql-align .ql-picker-item[data-value="right"]',
			title: '居右对齐'
		},
		{
			Choice: '.ql-align .ql-picker-item[data-value="justify"]',
			title: '两端对齐'
		}
	];
	// vue-quill-editor富文本编辑器鼠标移入工具栏按钮的标题文字end




	export default {
		name: '',
		components: {
			uploadImg,
			quillEditor,
			uploadVideo
		},
		props: {
			goods_id: ''
		},
		data() {

			return {
				plSkuObj: {
					supply_price: '',
					original_price: '',
					price: '',
					stock: '',
					img:[]
				}, //批量设置sku表格值

				loading: false,
				tabsActive: '1', //tabs默认选中项

				editorOption: { //  富文本编辑器配置
					modules: {
						toolbar: {

							container: [



								['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
								//['blockquote', 'code-block'], //引用，代码块


								// [{
								// 	'header': 1
								// }, {
								// 	'header': 2
								// }], // 标题，键值对的形式；1、2表示字体大小
								// [{
								// 	'list': 'ordered'
								// }, {
								// 	'list': 'bullet'
								// }], //列表
								// [{
								// 	'script': 'sub'
								// }, {
								// 	'script': 'super'
								// }], // 上下标
								// [{
								// 	'indent': '-1'
								// }, {
								// 	'indent': '+1'
								// }], // 缩进
								// [{
								// 	'direction': 'rtl'
								// }], // 文本方向



								// [{
								// 	'header': [1, 2, 3, 4, 5, 6, false]
								// }], //几级标题


								[{
									'color': []
								}, {
									'background': []
								}], // 字体颜色，字体背景颜色
								// [{
								// 	'font': fonts 
								// }], //字体
								[{
									'align': []
								}], //对齐方式


								//['clean'], //清除字体样式
								//, 'video'
								['image', 'video'],
								[{
									'size': sizes
								}], // 字体大小
							], //上传图片、上传视频
							handlers: {
								'image': function(value) {
									console.log('上传图片')
									if (value) {

										// 调用图片上传
										console.log('value=>', value)
										console.log('=>', document.querySelector('#goodsDesc .my-el-upload'))
										document.querySelector('#goodsDesc .my-el-upload .el-upload__input').click(); //触发上传文件的点击事件
									} else {
										this.quill.format('image', false);
									}
								},
								'video': function(value) {
									console.log('上传视频')

									if (value) {

										// 调用视频上传
										console.log('value=>', value)
										console.log('=>', document.querySelector('#goodsDescVide .my-el-upload'))
										document.querySelector('#goodsDescVide .my-el-upload .el-upload__input').click(); //触发上传文件的点击事件
									} else {
										this.quill.format('image', false);
									}
								}
							}
						}

					},
					theme: 'snow',
					placeholder: '请输入商品详情'
				},

				form1: {

					typeArr: [], //分类
					name: '', //商品名称
					jia_sale:'',//商品假销量名

					image: [], //封面图
					images: [], //商品轮播图
					is_recommend: false, //是否推荐
					is_tejia:false,//是否特价
					is_discount: false, //是否优惠
					is_swiper: false, //是否轮播
					index_swiper_img: [], //轮播的封面图

				}, //基础信息表单1
				brand_idArr: [], //品牌列表
				form1Rules: {

					name: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}],
					jia_sale: [{
						required: true,
						message: '请输入商品假销量名称',
						trigger: 'blur'
					}],
					typeArr: [{
						type: 'array',
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}],

					image: [{
						type: 'array',
						required: true,
						message: '请选择上传封面图',
						trigger: 'change'
					}],


					images: [{
						type: 'array',
						required: true,
						message: '请选择上传轮播图',
						trigger: 'change'
					}],

				}, //基础信息表单1的校验

				specs_type: "1", //1为单规格，2为多规格
				form2By1: {

					price: '', //售价
					stock: '' //库存
				}, //规格库存的表单-单规格
				form2By2: {

				}, //规格库存的表单-多规格
				form2By1Rules: {

					price: [{
						required: true,
						message: '请输入商品价格',
						trigger: 'blur'
					}],
					stock: [{
						required: true,
						message: '请输入商品库存',
						trigger: 'blur'
					}],

				}, //规格库存表单-单规格的校验规则
				form2By2Rules: {

				}, //规格库存表单-多规格的校验规则

				addSkuName: '', //要添加的sku名称


				skuTable: [], //提交给后端的表格数据，[ { "name": "蓝色","supply_price":'', "original_price": "", "price": "", "stock": "", "is_defaultBool": false, "specs_value_ids": [ 5 ] } ] sku表格数据,[ { "name": 'sku组合名称', "original_price": 原价, "stock": 库存, "is_defaultBool": "是否默认", "specs_value_ids":'sku组合id'} ]

				list: [], //[ { name: '尺码', list: [{name:'M',id:'xxx'}] } ] sku列表
				checkList: [], //{ name: '尺码', list: [] } 单选框上选中的sku项的id
				skuArray: [],
				skuList: [], //选中的sku项 [ [ id1,id2],[ id1,id2] ]


				form3: {
					description: '', //商品描述
				},
				form3Rules: {
					description: [{
						required: true,
						message: '请输入商品详情',
						trigger: 'blur'
					}]
				},

				typeOptions: [], //分类列表


			}
		},
		mounted() {

			this.getTypeArrArr(); //获取一级和二级分类


			//富文本按钮提示文字
			setTimeout(() => {
				//注意：let tip = document.querySelector('.quill-editor '+ item.Choice)这一行中的‘.quill-editor ’引号里后面有一个空格，如果把空格删掉，这段代码就不生效了
				autotip: {
					document.getElementsByClassName('ql-editor')[0].dataset.placeholder = ''
					for (let item of titleConfig) {
						let tip = document.querySelector('.quill-editor ' + item.Choice)
						if (!tip) continue
						tip.setAttribute('title', item.title)
					}
				}
			}, 2000);

		},
		methods: {



			//回显数据
			async getGoodsDetail() {

				this.loading = true
				let res = await ajax('/getGoodsDetail', 'GET', {
					id: this.goods_id
				}); //获取sku名称列表
				let goods = res.data.data.goodsDetail;

				this.loading = false;

				console.log('goods=>', goods)

				//分类级联默认值数组中的第一个值要字符串，第二个是数字
				this.form1.typeArr = [goods.category1_id + "", goods.category2_id]; //分类默认选中项

				console.log('this.form1.typeArr', this.form1.typeArr)

				this.form3.description = goods.goods_des; //商品详情富文本
				this.form1.name = goods.name; //商品名称
				this.form1.jia_sale = goods.jia_sale, //商品假销量名称
				this.form1.image = [{
					url: goods.cover_img
				}]; //封面图

				let imagesArr = goods.swiper_img.split(',');
				let images = [];
				for (let s of imagesArr) {
					images.push({
						url: s
					})
				} //轮播图
				this.form1.images = images; //商品轮播图
				console.log('goods=>', goods)
				this.form1.is_recommend = goods.is_new ? true : false; //是否推荐
				this.form1.is_tejia = goods.is_tejia ? true : false; //是否推荐
				
				this.form1.is_swiper = goods.is_swiper ? true : false; //是否轮播
				if (goods.is_swiper) {
					this.form1.index_swiper_img = [{
						url: goods.index_swiper_img
					}]; //轮播封面图
				}
				this.form1.is_discount = goods.is_hot ? true : false; //是否优惠
				this.specs_type = goods.sku_type; //1为单规格，2为多规格

				let specs = JSON.parse(goods.sku_list); //sku数组
				if (!specs) {
					specs = [];
				}
				if (goods.sku_type == 1) { //单sku
					this.form2By1.price = goods.price; //售价
					this.form2By1.stock = goods.stock; //库存
				}


				//所有sku列表 [ { "id": 1,"name": "尺码", "list": [ { "id": 1, "specs_id": 1 ] }]
				this.list.forEach((item1) => { //回显sku规格值选中项
					item1.list.forEach((item2) => { //sku项

						specs.forEach((item3) => { //接口返回的选中的sku项组合

							let specs_value_idsArr = item3.sku_value_ids.split(',');
							specs_value_idsArr.forEach((skuTxtItem) => {

								if (skuTxtItem == item2.id) { //如果当前sku项存在sku项组合中就添加到已选中的sku项的数组中进行回显



									this.checkList.forEach((item4) => { //循环已选中的sku项列表数组
										if (item4.name == item1.name && item4.list.indexOf(item2.id) == -1) {
											item4.list.push(item2.id);
											item4.isCheck = true;
											console.log('进来了')
										}
									});
								}

							});

						});


					})

				})


				console.log('===>this.checkList2222===>', this.checkList)


				this.handleClick(); //生成表格

				//回显生成的表格上的数据 
				this.skuTable.forEach((item1) => {
					specs.forEach((item2) => {
						if (item1.sku_value_ids == item2.sku_value_ids) {

							item1.price = item2.price; //售价
							item1.stock = item2.stock; //库存
							item1.img = [{url:item2.img}];//sku图片
						}
					})
				});

				let skuTableArrP = [];
				this.skuTable.forEach((item1) => { //过滤出没有填值的表格项
					if (item1.price) {
						skuTableArrP.push(item1)
					}
				});
				this.skuTable = skuTableArrP;

			},




			//获取一级和二级分类列表
			async getTypeArrArr() {
				this.loading = true
				let res = await ajax('/getCategoryAll', 'GET'); //获取一级分类列表

				let arr = res.data.data.list;
				console.log('一级分类=>', arr)


				let typeArr = [];
				for (let i of arr) {
					let obj = {
						value: i.id + '',
						label: i.name,
						children: []
					};




					for (let ii of i.category2List) {
						obj.children.push({
							value: ii.id,
							label: ii.name
						});
					}
					typeArr.push(obj);
				}

				this.loading = false;
				this.typeOptions = typeArr;

				this.getSkuList(); //获取sku列表

			},

			//sku上默认的滑块改变事件
			skuSwitchChange(i) {
				this.skuTable.forEach((item, index) => {
					if (index != i) {
						item.is_default = false; //默认选中的sku项
						item.is_defaultBool = false; //默认选中的sku项
					}

				})

			},

			//批量添加多规格sku数据
			plAddSkuTableData() {

				this.plSkuObj.img = this.$refs.duoSkuPiLiangImgUpload.getImgList(); //sku规格封面图
				if (!this.plSkuObj.img || this.plSkuObj.img.length==0) {
					this.$message.error('请上传图片');
					return
				}
				this.skuTable.forEach((item, index) => {
					item.price = this.plSkuObj.price;
					item.stock = this.plSkuObj.stock;
					item.img = [{
						url: this.plSkuObj.img[0].url
					}]; //新建数组，否则会引发级联反应
				})
			},

			//多sku的项上传图片成功
			duoSkuItemImgUploadSuccess(imgUrl, eleIndex) {

				// 如果上传成功
				if (imgUrl.indexOf('http') != -1) {
					this.skuTable[eleIndex].img = [{
						url: imgUrl
					}];
					console.log('this.skuTable', this.skuTable);
				} else {
					// 提示信息，需引入Message
					Message.error('图片插入失败')
				}

			},

			delSku(index) {
				this.skuTable.splice(index, 1);
			},

			//上传图片和视频时是否显示loading
			isShowLoadingFn(bool) {
				this.loading = bool
			},
			//富文本上的上传视频成功事件
			quillUploadVideoSuccess(url) {
				// 获取富文本组件实例
				let quill = this.$refs.myQuillEditor.quill
				// 如果上传成功
				if (url.indexOf('http') != -1) {
					// 获取光标所在位置
					let length = quill.getSelection().index;


					// 插入视频，res为服务器返回的视频链接地址
					quill.insertEmbed(length, 'video', url)
					// 调整光标到最后
					quill.setSelection(length + 1)



				} else {
					// 提示信息，需引入Message
					Message.error('视频插入失败')
				}
			},
			//富文本上的图片上传成功事件
			quillUploadImgSuccess(imgUrl) {
				// 获取富文本组件实例
				let quill = this.$refs.myQuillEditor.quill
				// 如果上传成功
				if (imgUrl.indexOf('http') != -1) {
					// 获取光标所在位置
					let length = quill.getSelection().index;


					// 插入图片，res为服务器返回的图片链接地址
					quill.insertEmbed(length, 'image', imgUrl)
					// 调整光标到最后
					quill.setSelection(length + 1)


				} else {
					// 提示信息，需引入Message
					Message.error('图片插入失败')
				}
			},

			//选中或取消选中sku项
			handleClick() {
			
				this.list.forEach((item1) => { //当前sku项，这个item1里有sku标题，标题下面是sku值
					item1.list.forEach((item2) => { //item1.list是sku标题下的每个sku值

						item2.isCheck = false;
					});
				})
				
				

				let skuTableOld = this.skuTable;
				// 先清空数据，保证连续点击按钮，数据不会重复
				this.skuArray = []; //[['规格值', ‘规格值’], [a, b]...]
				this.skuList = [];
				this.skuTable = [];
				console.log('this.checkList333', this.checkList)
				// 将选中的规格组合成一个大数组 [[1, 2], [a, b]...]
				this.checkList.forEach(element => {
					element.list.length > 0 ? this.skuArray.push(element.list) : ''
				})


				// 勾选了规格，才调用方法
				if (this.skuArray.length > 0) {
					this.getSkuData([], 0, this.skuArray);



					//this.skuArr //sku数组，[ {id:11,name:'规格名称',skuValInput:'输入框值',child:[{id:11,name:'规格值'},checkSkuValArr:[{..}]选中项的对象]} ]
					let nameArr = []; //['id-id','id']
					console.log('==', this.skuList)
					this.skuList.forEach((arr) => { //[[id1,id2],[id1,id2]]
						let str = '';
						arr.forEach((id) => {

							this.list.forEach((item1) => {
								item1.list.forEach((item2) => {

									if (item2.id == id) {
										item2.isCheck = true;
										console.log(222, item2)
										str = str ? str + '-' + item2.sku_value : item2.sku_value;
									}
								});
							})

						});
						nameArr.push(str);
					})

					console.log('nameArr', nameArr);
					let skuTableArr = [];
					nameArr.forEach((item, index) => {
						console.log('item==>', item)
						let skuZuHeObj = {}; //sku组合项对象

						skuZuHeObj.name = item; //自定义sku组合项名称 红色-m


						skuZuHeObj.price = ''; //自定义售价
						skuZuHeObj.stock = ''; //自定义库存
						skuZuHeObj.img = []; //自定义图片
						skuZuHeObj.sku_value_ids = this.skuList[index].toString(); //自定义规格值

						skuTableArr.push(skuZuHeObj);
					});




						this.skuTable = skuTableArr;

					console.log('选中的sku this.skuTable=>', this.skuTable)
				} else if (this.specs_type == '2') {
					this.$message.error('请先勾选规格')
				}
			},
			// 递归获取每条SKU数据
			getSkuData(skuArr = [], i, list) {
				for (let j = 0; j < list[i].length; j++) {
					if (i < list.length - 1) {
						skuArr[i] = list[i][j]
						this.getSkuData(skuArr, i + 1, list) // 递归循环
					} else {

						this.skuList.push([...skuArr, list[i][j]]) // 扩展运算符，连接两个数组
					}
				}
			},


			//获取sku列表
			async getSkuList() {
				this.loading = true
				let res = await ajax('/getSkuList', 'GET'); //获取sku名称列表
				let arr = res.data.data;
				console.log('arr', arr)
				this.loading = false;
				let list = [];
				let checkList = [];


				for (let item1 of arr) {
					//获取sku名称对应的sku规格值列表
					let childArr = item1.skuValueList;

					list.push({
						name: item1.sku_title,
						list: childArr,
						id: item1.id
					})
					checkList.push({
						name: item1.sku_title,
						list: []
					})
				}


				this.list = list;
				this.checkList = checkList;

				this.getGoodsDetail(); //获取商品详情
			},


			//添加sku名称
			async addSkuNameFn() {
				if (!this.addSkuName.trim()) {
					this.$message.error('请填写规格名称');
					return
				}
				let bool = false;

				for (let item of this.list) { //判断是否重名

					if (item.name == this.addSkuName) {
						this.$message.error('规格名称已存在');
						return;
					}
				}


				this.loading = true
				let res = await ajax('/addSkuTitle', 'POST', {
					sku_title: this.addSkuName
				});
				this.addSkuName = '';
				this.loading = false;
				let obj = res.data.data; //新添加的规格对象
				obj.skuValInput = ''; //输入框值
				obj.name = obj.sku_title;
				obj.list = []; //sku规格值列表
				this.checkList.push({
					name: obj.name,
					list: []
				});
				this.list.push(obj);
			},

			//添加sku规格值
			async addSkuValueFn(specs_id, index) {
				console.log('this.list,', this.list);
				console.log('specs_id', specs_id)
				let item = this.list[index];
				if (!item.skuValInput) {
					this.$message.error('请填写规格值');
					return
				}

				for (let i of item.list) { //判断是否重名

					if (i.name == item.skuValInput) {
						this.$message.error('规格值已存在');
						return;
					}
				}


				this.loading = true
				let res = await ajax('/addSkuValue', 'POST', {
					sku_name_id: specs_id,
					sku_value: item.skuValInput
				});
				item.skuValInput = '';
				this.loading = false;
				let obj = res.data.data;
				obj.name = obj.sku_value;

				item.list.push(obj); //sku规格值列表

			},







			//下一个标签栏,校验表单
			async goNext(formName) {
				// //测试代码，后面删除start
				// if (formName == 'form1') {
				// 	this.tabsActive = (parseInt(this.tabsActive) + 1) + '';
				// 	return
				// }
				// //测试代码，后面删除end


				if (formName == 'form1') { //是基础信息点击的下一步
					this.form1.image = this.$refs.fengMianUpload.getImgList(); //商品封面图
					this.form1.images = this.$refs.goodsImgUpload.getImgList(); //商品详情图
					if (this.form1.is_swiper) { //是否轮播
						this.form1.index_swiper_img = this.$refs.recommendImgUpload.getImgList(); //轮播封面图

						if (this.form1.index_swiper_img.length == 0) {
							this.$message.error('请上传轮播封面图');
							return false;
						}
					}

				}
				if (formName == 'form3') { //是商品详情富文本的下一步

					if (!this.form3.description.trim()) {
						this.$message.error('请填写商品详情');
						return false;
					}

				}
				this.$refs[formName].validate(async (valid) => {

					if (valid) { //校验成功



						if (this.specs_type == '2' && formName == 'form2By2') { //多规格的校验
							if (this.skuTable.length == 0) {
								this.$message.error('请填写规格');
								return
							}
							for (let i of this.skuTable) {
								console.log("===", i.price)
								
								if (!i.img || i.img.length==0) {
									this.$message.error('请上传图片');
									return
								}

								if (!(i.price + "").trim()) {
									this.$message.error('请填写商品价格');
									return
								}
								if (!i.stock && i.stock === "") {
									this.$message.error('请填写商品库存');
									return
								}


							}

							console.log('选中的sku列表', this.skuTable)
							this.tabsActive = (parseInt(this.tabsActive) + 1) + '';
							return
						}


						console.log('校验成功')





						//提交商品start
						let swiperImgArr = []; //轮播图
						for (let i of this.form1.images) {
							swiperImgArr.push(i.url);
						}

						let specs = []; //商品规格sku
						if (this.specs_type == '1') { //单规格
							specs.push({

								price: this.form2By1.price, //售价
								stock: this.form2By1.stock, //库存

							})
						}
						if (this.specs_type == '2') { //多规格
							console.log('this.skuTable=>', this.skuTable)

							for (let s of this.skuTable) {
								specs.push({

									name: s.name, //sku组合值名称 红-M
									sku_value_ids: s.sku_value_ids, //sku组合的ids 1,2,3

									price: s.price, //售价
									stock: s.stock, //库存
									img:s.img[0].url//sku图片

								})
							}
						}

						console.log('this.form1.is_recommend', this.form1.is_recommend)
						console.log('this.form1.is_discount', this.form1.is_discount)
						if (formName == 'form3') { //提交商品
						
							let arr = [];
							console.log('this.list=======>>>>>>>',this.list);
							this.list.forEach((item1) => { //当前sku项，item1.name为标题名称 这个item1里有sku标题，标题下面是sku值
								item1.list.forEach((item2) => { //item1.list是sku标题下的每个sku值
									if (item2.isCheck) {

										let falg = true;
										arr.forEach((item3) => {
											if (item3.skuTitle == item1.name) {
												item3.skuValueList.push(item2);
												falg = false;
											}

										})

										if (falg) {
											arr.push({
												skuTitle: item1.name, //sku标题
												skuValueList: [item2] //sku项
											});
										}


									}

								});
							})




							console.log('选中的标题项和sku值=》', arr)
							
							let categoryTxt = "";
							for (let item1 of this.typeOptions) {
								if (this.form1.typeArr[0] == item1.value) {
									categoryTxt += item1.label + '-';
									for (let item2 of item1.children) {
										if (item2.value == this.form1.typeArr[1]) {
											categoryTxt += item2.label;
										}
									}
								}
							}

							this.loading = true;
							let param = {
								"categoryTxt": categoryTxt, //分类的文字
								"sku_check_title_and_val": this.specs_type == 2 ? JSON.stringify(arr) : null, //选中项的sku标题和sku项 [{skuTitle:"颜色",skuValueList:[{id: 12,isCheck: true,sku_name_id: 16,sku_value: "大"}] }]
								"id": this.goods_id,
								"category1_id": Number(this.form1.typeArr[0]), //一级分类id
								"category2_id": this.form1.typeArr[1], //二级分类id
								"name": this.form1.name, //商品名称
								"jia_sale":this.form1.jia_sale,//商品假销量名
								"price": this.specs_type == 1 ? this.form2By1.price : specs[0].price,
								"stock": parseInt(this.specs_type) == 1 ? this.form2By1.stock : specs[0].stock,
								"cover_img": this.form1.image[0].url, //封面图
								"swiper_img": swiperImgArr.toString(),
								"goods_des": this.form3.description, //商品详情富文本
								"is_hot": this.form1.is_discount ? 1 : 0,
								"is_show": 1,
								"is_new": this.form1.is_recommend ? 1 : 0,
								"is_tejia": this.form1.is_tejia ? 1 : 0,
								
								"is_swiper": this.form1.is_swiper ? 1 : 0,
								"index_swiper_img": this.form1.index_swiper_img.length > 0 ? this.form1.index_swiper_img[0].url : "",
								"sku_type": parseInt(this.specs_type), //1单规格，2多规格
								"sku_list": this.specs_type == 2 ? JSON.stringify(specs) : null
							};
							console.log('param==>', param)
							let ress = await ajax('/addOrEditGoods', 'POST', param);
							this.loading = false;
							this.$message({
								message: '成功',
								type: 'success'
							});

							this.$emit('success')
							return true;
							//提交商品end
						}


						this.tabsActive = (parseInt(this.tabsActive) + 1) + '';

					} else { //校验失败
						console.log('校验失败')
						this.$message.error('请完善信息');
						return false;
					}


				});

			},

			//上一个标签栏
			goUp() {
				this.tabsActive = (parseInt(this.tabsActive) - 1) + '';
			},



		}
	}
</script>

<style scoped>
	>>>.el-tabs__item.is-active.is-disabled {
		color: #9A7E9C;
	}

	>>>.el-tabs__item.is-disabled {
		color: #303133;
	}


	table.gridtable {
		width: 100%;
		font-family: verdana, arial, sans-serif;
		font-size: 11px;
		color: #333333;
		border-width: 1px;
		border-color: #666666;
		border-collapse: collapse;
	}

	table.gridtable th {
		border-width: 1px;
		padding: 8px;
		border-style: solid;
		border-color: #666666;
		background-color: #dedede;
	}

	table.gridtable td {
		border-width: 1px;
		padding: 8px;
		border-style: solid;
		border-color: #666666;
		background-color: #ffffff;
		text-align: center;
	}
</style>

<style>
	/* 富文本start */
	/* 需要把这些样式也复制到小程序的富文本那里 */
	.ql-container {
		height: 595px !important;
	}

	.ql-snow .ql-editor img {
		vertical-align: top;
	}

	.ql-toolbar {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}

	.ql-toolbar.ql-snow .ql-picker-label {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ql-align-center {
		text-align: center;
	}

	.ql-video {
		width: 100%;
	}

	.ql-align-right {
		text-align: right;
	}

	.ql-align-justify {
		text-align: justify;
	}

	.ql-align-center img {
		max-width: 100%;
		vertical-align: top;
	}

	#my-phone img {
		max-width: 100%;
		vertical-align: top;
	}

	.ql-align-center video {
		max-width: 100%;
		vertical-align: top;
	}

	.ql-editor .ql-video {
		max-width: 100%;
		vertical-align: top;
	}






	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
		content: "黑体";
		font-family: "SimHei";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
		content: "微软雅黑";
		font-family: "Microsoft YaHei";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
		content: "楷体";
		font-family: "KaiTi";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
		content: "仿宋";
		font-family: "FangSong";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
		content: "Arial";
		font-family: "Arial";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Times-New-Roman]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Times-New-Roman]::before {
		content: "Times New Roman";
		font-family: "Times New Roman";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
		content: "sans-serif";
		font-family: "sans-serif";
	}

	.ql-font-SimSun {
		font-family: "SimSun";
	}

	.ql-size .ql-picker-label {
		justify-content: flex-start !important;
	}

	.ql-snow .ql-picker.ql-size {
		/* min-width: 60px !important; */
	}

	.ql-font-SimHei {
		font-family: "SimHei";
	}

	.ql-font-Microsoft-YaHei {
		font-family: "Microsoft YaHei";
	}

	.ql-font-KaiTi {
		font-family: "KaiTi";
	}

	.ql-font-FangSong {
		font-family: "FangSong";
	}

	.ql-font-Arial {
		font-family: "Arial";
	}

	.ql-font-Times-New-Roman {
		font-family: "Times New Roman";
	}

	.ql-font-sans-serif {
		font-family: "sans-serif";
	}

	/* 字号设置 */
	/* 默认字号 */
	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
		content: "14px";
		font-size: 14px;
	}

	.ql-size-14px {
		font-size: 14px;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
		content: "16px";
		font-size: 16px;
	}

	.ql-size-16px {
		font-size: 16px;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
		content: "18px";
		font-size: 18px;
	}

	.ql-size-18px {
		font-size: 18px;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
		content: "20px";
		font-size: 20px;
	}

	.ql-size-20px {
		font-size: 20px;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
		content: "22px";
		font-size: 22px;
	}

	.ql-size-22px {
		font-size: 22px;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
		content: "26px";
		font-size: 26px;
	}

	.ql-size-26px {
		font-size: 26px;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
		content: "28px";
		font-size: 28px;
	}

	.ql-size-28px {
		font-size: 28px;
	}

	.ql-editor {
		padding: 0 !important;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
		content: "30px";
		font-size: 30px;
	}

	.ql-size-30px {
		font-size: 30px;
	}
	.ql-editor .ql-video{
		height: 225px !important;
	}

	/* 富文本样式 */
</style>
